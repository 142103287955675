import { useState, useEffect, useRef } from "react"
import { Card, Button, Dropdown, Badge } from "react-bootstrap"
import { useNavigate, useParams, Link } from "react-router-dom"
import lawyerService from "../services/lawyerService"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"
import { Descriptions } from "antd"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"
import leadService from "../../lead/services/leadService.js"

const LawyerInfo = ({
  lawyerData,
  UserList,
  Lawyer_schoolList,
  Posts_planList,
}) => {
  const navigate = useNavigate()
  return (
    <Descriptions
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
            border: "1px solid #e0e0e0",
            borderRadius: "10px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* Contenedor de imagen de perfil */}
            <div
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                backgroundColor: lawyerData.image_url
                  ? "transparent"
                  : "gray",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                marginRight: "20px",
                border: "2px solid #ccc",
              }}
            >
              {lawyerData.image_url ? (
                <img
                  src={lawyerData.image_url}
                  alt="Perfil abogado"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <span
                  style={{
                    color: "white",
                    fontSize: "24px",
                  }}
                >
                  AB
                </span>
              )}
            </div>

            <h4
              style={{
                margin: 0,
                fontSize: "20px",
                fontWeight: "500",
                color: "#333",
              }}
            >
              {lawyerData.name} {lawyerData.last_name}
            </h4>
          </div>

          <Button
            variant="primary"
            onClick={() => navigate("/lawyer/edit/" + lawyerData.id)}
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              fontWeight: "bold",
              borderRadius: "8px",
              color: "#fff",
            }}
          >
            Actualizar Perfil
          </Button>
        </div>
      }
      layout="vertical"
    >
      <Descriptions.Item label="Name">{lawyerData.name}</Descriptions.Item>
      <Descriptions.Item label="Last Name">
        {lawyerData.last_name}
      </Descriptions.Item>
      <Descriptions.Item label="Phone Number">
        {lawyerData.phone_number}
      </Descriptions.Item>
      <Descriptions.Item label="Email">{lawyerData.email}</Descriptions.Item>
      <Descriptions.Item label="Business Direction">
        {lawyerData.business_direction}
      </Descriptions.Item>
      <Descriptions.Item label="Business Name">
        {lawyerData.business_name}
      </Descriptions.Item>
      <Descriptions.Item label="Lawyer School Code">
        {lawyerData.lawyer_school_code}
      </Descriptions.Item>
      <Descriptions.Item label="About Me">
        {lawyerData.about_me}
      </Descriptions.Item>
      <Descriptions.Item label="User">
        {UserList?.find((user) => user.id === lawyerData.user)?.email || "N/A"}
      </Descriptions.Item>
      <Descriptions.Item label="Lawyer School">
        {Lawyer_schoolList?.find(
          (school) => school.id === lawyerData.lawyer_school
        )?.name || "N/A"}
      </Descriptions.Item>
      <Descriptions.Item label="Locality">
        {lawyerData.locality ? lawyerData.locality : "N/A"}
      </Descriptions.Item>
      <Descriptions.Item label="Posts Plan">
        {Posts_planList?.find((plan) => plan.id === lawyerData.posts_plan)
          ?.name || "N/A"}
      </Descriptions.Item>
      <Descriptions.Item label="Directory Visibility">
        {lawyerData.default_directory_visibility ? "Yes" : "No"}
      </Descriptions.Item>
      <Descriptions.Item label="Geo Location Latitude">
        {lawyerData.geo_location_lat}
      </Descriptions.Item>
      <Descriptions.Item label="Geo Location Longitude">
        {lawyerData.geo_location_long}
      </Descriptions.Item>
      <Descriptions.Item label="Website">
        {lawyerData.website}
      </Descriptions.Item>
      <Descriptions.Item label="Optimization Level">
        {lawyerData.optimization_level}
      </Descriptions.Item>
      <Descriptions.Item label="Complete Later">
        {lawyerData.complete_later ? "Yes" : "No"}
      </Descriptions.Item>
    </Descriptions>
  )
}
const expandedComponent = ({ data }) => {
  if (!data.lead_comment || !data.lead_comment.length) {
    return <p>Sin comentarios</p>
  }
  return (
    <ul className="data-details p-3 gap gy-1 border-bottom small">
      Comentarios
      {data.lead_comment
        ?.slice()
        .reverse()
        .map((comment) => (
          <li key={comment.id}>
            <span className="data-title text-base fw-medium me-2">
              {comment.message_date}:
            </span>
            <span className="data-text text-light">{comment.message}</span>
          </li>
        ))}
    </ul>
  )
}

function LawyerDetails() {
  const { id } = useParams()
  const [lawyerData, setLawyerData] = useState({
    name: "",
    last_name: "",
    phone_number: "",
    email: "",
    image: "",
    business_direcction: "",
    business_name: "",
    lawyer_school_code: "",
    about_me: "",
    complete_later: false,
    default_directory_visibility: false,
    is_active: true,
    website: "https://abogueo.com/",
  })
  const [UserList, setUserList] = useState([])
  const [Lawyer_schoolList, setLawyer_schoolList] = useState([])
  const [Posts_planList, setPosts_planList] = useState([])
  const [initialLoading, setInitialLoading] = useState(false)
  const [leadData, setLead] = useState([])
  const [loading, setLoading] = useState(false)
  const [specialtyData, setSpecialty] = useState([])
  const [provinceData, setProvince] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  useEffect(() => {
    fetchAll()
  }, [id])

  const fetchAll = async () => {
    try {
      setInitialLoading(true)
      setLoading(true)
      await Promise.all([
        fetchLawyer(),
        fetchUserList(),
        fetchLawyer_schoolList(),
        fetchPosts_planList(),
        fetchSpecialtyList(),
        fetchProvinceList(),
      ])
    } catch (error) {
      console.log("Error fetching lawyer:", error)
    } finally {
      setInitialLoading(false)
      setLoading(false)
    }
  }

  const fetchLeadList = async (
    pageNumber = 1,
    filters = {},
    limit = 10,
    lawyerId = lawyerData?.id
  ) => {
    const response = await leadService.getLeadListPaginateByLawyer(
      pageNumber,
      { ...filters, lawyer: lawyerId },
      limit
    )
    setLead(response.results)
    setCount(response.count)
    setPage(pageNumber)
  }

  const fetchSpecialtyList = async () => {
    const specialty = await leadService.getSpecialtyList()
    setSpecialty(specialty)
  }

  const fetchProvinceList = async () => {
    const province = await leadService.getProvinceList()
    setProvince(province)
  }

  const paginateLeadList = async (pageNumber = 1) => {
    try {
      setLoading(true)
      await fetchLeadList(pageNumber)
    } catch (error) {
      console.log("Error fetching lead list:", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchLawyer = async () => {
    try {
      const lawyer = await lawyerService.getAllLawyerDetails(id)
      if (!lawyer.website) {
        lawyer.website = "https://abogueo.com/"
      }
      setLawyerData(lawyer)
      await fetchLeadList(1, {}, 10, lawyer.id)
    } catch (error) {
      console.log("Error fetching lawyer:", error)
    }
  }

  const fetchUserList = async () => {
    try {
      const response = await lawyerService.getUserList()
      setUserList(response)
    } catch (error) {
      console.error("Error fetching Vip_plan:", error)
    }
  }

  const fetchLawyer_schoolList = async () => {
    try {
      const response = await lawyerService.getLawyer_schoolList()
      setLawyer_schoolList(response)
    } catch (error) {
      console.error("Error fetching Vip_plan:", error)
    }
  }

  const fetchPosts_planList = async () => {
    try {
      const response = await lawyerService.getPosts_planList()
      setPosts_planList(response)
    } catch (error) {
      console.error("Error fetching Vip_plan:", error)
    }
  }

  const leadColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "date",
      selector: (row) => {
        const date = new Date(row.assignation_date)
        return date.toLocaleString("es-ES", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
      },
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "last_name",
      selector: (row) => row.last_name,
      sortable: true,
    },
    {
      name: "estimated_value",
      selector: (row) => {
        return row.estimated_value ? `${row.estimated_value} €` : "-"
      },
      sortable: true,
    },
    {
      name: "email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "phone_number",
      selector: (row) => row.phone_number,
      sortable: true,
    },
    // {
    //   name: "commentary",
    //   selector: (row) => row.commentary,
    //   sortable: true,
    // },
    {
      name: "specialty",
      selector: (row) => {
        const specialty = specialtyData.find(
          (specialty) => specialty.id === row.specialty
        )
        return specialty ? specialty.child_specialty : ""
      },
      sortable: true,
      cell: (row) => {
        const specialty = specialtyData.find(
          (specialty) => specialty.id === row.specialty
        )
        return specialty ? specialty.child_specialty : ""
      },
    },
    {
      name: "province",
      selector: (row) => {
        const province = provinceData.find(
          (province) => province.id === row.province
        )
        return province ? province.name : ""
      },
      sortable: true,
      cell: (row) => {
        const province = provinceData.find(
          (province) => province.id === row.province
        )
        return province ? province.name : ""
      },
    },
    {
      name: "sms_verified",
      selector: (row) => {
        if (row.sms_verified) {
          return <Badge bg="success">Yes</Badge>
        } else {
          return <Badge bg="warning">No</Badge>
        }
      },
      sortable: true,
    },
    {
      name: "state",
      selector: (row) => {
        if (row.lead_state) {
          return <Badge bg="success">{row.lead_state}</Badge>
        } else {
          return <Badge bg="danger">Sin estados</Badge>
        }
      },
      sortable: true,
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link to={`/lead/edit/${row.id}`} className="dropdown-item">
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  const pageTitle = "Perfil Abogado"

  return (
    <Layout title={pageTitle} content="container" loading={initialLoading}>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div className="bio-block">
              <LawyerInfo
                lawyerData={lawyerData}
                UserList={UserList}
                Lawyer_schoolList={Lawyer_schoolList}
                Posts_planList={Posts_planList}
              />
            </div>
          </Card.Body>
        </Card>
      </Block>
      <Block>
        <Card className="card-gutter-md">
          <Card.Body>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h4 className="bio-block-title mb-4">Leads asignados</h4>
            </div>
            {!loading && (
              <DataTable
                tableClassName="data-table-head-light table-responsive"
                data={leadData}
                columns={leadColumns}
                expandableRows
                actions
                searchByList={["name", "last_name", "email", "phone_number"]}
                backEndPagination={true}
                fetchPaginatedData={paginateLeadList}
                pageCount={count}
                currentPaginationPage={page}
                backendFilter={true}
                filterFunc={fetchLeadList}
                isLeadForm={true}
                customExpandedComponent={expandedComponent}
              />
            )}
          </Card.Body>
        </Card>
      </Block>
    </Layout>
  )
}

export default LawyerDetails
