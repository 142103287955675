import { Card, Button, Dropdown, Badge } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import {
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
} from "../../../components"
import Layout from "../../../layout/default"
import Block from "../../../components/Block/Block"
import DataTable from "../../../components/DataTable/DataTable.js"
import React, { useEffect, useRef, useState } from "react"
import leadService from "../services/leadService"
import { message } from "antd"
import LeadAssignmentForm from "./AssignLeadModal.js"

const LeadList = () => {
  const navigate = useNavigate()
  const [leadData, setLead] = useState([])
  const [loading, setLoading] = useState(false)
  const [specialtyData, setSpecialty] = useState([])
  const [provinceData, setProvince] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [show, setShow] = useState(false)
  const [leadToAsigne, setLeadToAsigne] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = (row) => {
    setShow(true)
    setLeadToAsigne(row)
  }

  useEffect(() => {
    fetchAll()
  }, [])
  const updateLead = (leadId, lawyerId) => {
    setLead((prevLeadData) =>
      prevLeadData.map((lead) =>
        lead.id === leadId
          ? { ...lead, assignments: [...lead.assignments, lawyerId] }
          : lead
      )
    )
  }
  const fetchAll = async () => {
    try {
      setLoading(true)
      await Promise.all([
        fetchLeadList(),
        fetchSpecialtyList(),
        fetchProvinceList(),
      ])
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchLeadList = async (pageNumber = 1, filters = {}, limit = 10) => {
    const response = await leadService.getLeadListPaginate(
      pageNumber,
      filters,
      limit
    )
    setLead(response.results)
    setCount(response.count)
    setPage(pageNumber)
  }

  const paginateLeadList = async (pageNumber = 1) => {
    try {
      setLoading(true)
      await fetchLeadList(pageNumber)
    } catch (error) {
      console.log("Error fetching lead list:", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchSpecialtyList = async () => {
    const specialty = await leadService.getSpecialtyList()
    setSpecialty(specialty)
  }

  const fetchProvinceList = async () => {
    const province = await leadService.getProvinceList()
    setProvince(province)
  }

  const handleDeleteLead = async (leadId) => {
    try {
      setLoading(true)
      await leadService.deleteLead(leadId)
      const data = leadData.filter((rows) => rows.id != leadId)
      setLead(data)
      setLoading(false)
    } catch (error) {
      console.log("Error deleting lead:", error)
      setLoading(false)
    }
  }

  const handleCreateLead = () => {
    navigate("/lead/new")
  }

  const exportExcel = async (is_assigned = false) => {
    try {
      setLoading(true)
      const response = await leadService.exportLead(is_assigned)
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        })

        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = downloadUrl

        // Extrae el nombre del archivo del encabezado 'Content-Disposition'
        const contentDisposition = response.headers["content-disposition"]
        let filename = is_assigned ? "leads.xlsx" : "leads-sin-asignar.xlsx" // Nombre por defecto
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?(.+)"?/)
          if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1]
          }
        }

        link.download = filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(downloadUrl)
      } else {
        throw new Error("Error al descargar el archivo Excel")
      }
    } catch (error) {
      console.error("Error al descargar el archivo:", error)
      console.log(error)
      // showMessage("Error al descargar excel", true)
    } finally {
      setLoading(false)
    }
  }

  const handleFileUpload = async (event) => {
    try {
      setLoading(true)
      const file = event.target.files[0]
      if (!file) return
      const formData = new FormData()
      formData.append("file", file)
      const response = await leadService.importLead(formData)

      if (response.status === 200) {
        await fetchLeadList()
        message.success("File uploaded successfully")
      } else {
        message.error("Error uploading file")
      }
    } catch (error) {
      console.error("Error uploading file: ", error)
      message.error("Error uploading file")
    } finally {
      setLoading(false)
      // Resetea el valor del input de tipo file
      event.target.value = "" // Esto permite que el mismo archivo sea seleccionado nuevamente
    }
  }

  const leadColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "date",
      selector: (row) => {
        const date = new Date(row.assignation_date)
        return date.toLocaleString("es-ES", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
      },
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "last_name",
      selector: (row) => row.last_name,
      sortable: true,
    },
    {
      name: "estimated_value",
      selector: (row) => {
        return row.estimated_value ? `${row.estimated_value} €` : "-"
      },
      sortable: true,
    },
    {
      name: "email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "phone_number",
      selector: (row) => row.phone_number,
      sortable: true,
    },
    // {
    //   name: "commentary",
    //   selector: (row) => row.commentary,
    //   sortable: true,
    // },
    {
      name: "specialty",
      selector: (row) => {
        const specialty = specialtyData.find(
          (specialty) => specialty.id === row.specialty
        )
        return specialty ? specialty.child_specialty : ""
      },
      sortable: true,
      cell: (row) => {
        const specialty = specialtyData.find(
          (specialty) => specialty.id === row.specialty
        )
        return specialty ? specialty.child_specialty : ""
      },
    },
    {
      name: "province",
      selector: (row) => {
        const province = provinceData.find(
          (province) => province.id === row.province
        )
        return province ? province.name : ""
      },
      sortable: true,
      cell: (row) => {
        const province = provinceData.find(
          (province) => province.id === row.province
        )
        return province ? province.name : ""
      },
    },
    {
      name: "sms_verified",
      selector: (row) => {
        if (row.sms_verified) {
          return <Badge bg="success">Yes</Badge>
        } else {
          return <Badge bg="warning">No</Badge>
        }
      },
      sortable: true,
    },
    {
      name: "Asignación",
      selector: (row, idx) => (
        <div style={{ display: "flex", gap: "1rem" }}>
          {row.assignments && row.assignments?.length ? (
            <span key={idx} style={{ display: "flex", gap: "2%" }}>
              {row.assignments?.map((assignment) => (
                <Badge bg="info">
                  <Link to={`/lawyer/${assignment}`} >
                    <span style={{color:"white"}}>{assignment}</span>
                  </Link>
                </Badge>
              ))}
            </span>
          ) : (
            <Badge bg="danger">Sin asignar</Badge>
          )}
          {row?.assignments?.length < 3 && (
            <Button
              bg="primary"
              size="sm"
              style={{ maxHeight: "22.2px" }}
              onClick={() => handleShow(row)}
            >
              Asignar
            </Button>
          )}
        </div>
      ),
    },
    {
      name: "state",
      selector: (row) => {
        if (row.state && row.state.length) {
          return (
            <span style={{ display: "flex", gap: "2%" }}>
              {row.state.map((stateId) => (
                <Badge bg="success">{stateId}</Badge>
              ))}
            </span>
          )
        } else {
          return <Badge bg="danger">Sin estados</Badge>
        }
      },
      sortable: true,
    },
    {
      name: "actions",
      cell: (row) => (
        <div className="text-end w-100">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              as={CustomDropdownToggle}
              className="btn btn-sm btn-icon btn-zoom me-n1"
            >
              <Icon name="more-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-sm"
              as={CustomDropdownMenu}
              align="end"
            >
              <div className="dropdown-content py-1">
                <LinkList className="link-list-hover-bg-primary link-list-md">
                  <Link to={`/lead/edit/${row.id}`} className="dropdown-item">
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Link>
                  <button
                    className="dropdown-item"
                    onClick={() => handleDeleteLead(row.id)}
                  >
                    <Icon name="trash" />
                    <span>Delete</span>
                  </button>
                </LinkList>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      sortable: false,
    },
  ]

  const fileInputRef = useRef(null)
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <Layout title="Leads" content="container" loading={loading}>
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Leads</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Leads
                </li>
              </ol>
            </nav>
          </Block.HeadContent>

          <Block.HeadContent>
            <ul className="d-flex gap-2">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleCreateLead}
                >
                  <Icon name="plus" />
                  <span>Añadir</span>
                </Button>
              </li>
              <li>
                <a
                  href="/templates/Plantilla-importación.xlsx"
                  download="Plantilla-importación-leads.xlsx"
                  style={{ textDecoration: "none" }}
                >
                  <Button className="d-none d-md-inline-flex" variant="primary">
                    <Icon name="file" />
                    <span>Plantilla excel</span>
                  </Button>
                </a>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleUploadClick}
                >
                  <Icon name="file" />
                  <span>Importar</span>
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                  accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={() => exportExcel(true)}
                >
                  <span>Exportar</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={() => exportExcel(false)}
                >
                  <span>Exportar sin asignar</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleCreateLead}
                >
                  <Icon name="plus" />
                  <span>Añadir Lead</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card>
          {!loading && (
            <DataTable
              tableClassName="data-table-head-light table-responsive"
              data={leadData}
              columns={leadColumns}
              expandableRows
              actions
              searchByList={["name", "last_name", "email", "phone_number"]}
              backEndPagination={true}
              fetchPaginatedData={paginateLeadList}
              pageCount={count}
              currentPaginationPage={page}
              backendFilter={true}
              filterFunc={fetchLeadList}
              isLeadForm={true}
            />
          )}
        </Card>
      </Block>
      <LeadAssignmentForm
        show={show}
        handleClose={handleClose}
        lead={leadToAsigne}
        updateLead={updateLead}
      />
    </Layout>
  )
}

export default LeadList
